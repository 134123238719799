import LocalizedStrings, { LocalizedStringsMethods } from "localized-strings";
import en from "./en.json";
import fi from "./fi.json";

/**
 * Localized strings
 */
export interface Localized extends LocalizedStringsMethods {
  title: string;
  feedback: string;
  email: string;
  contactEmail: string;
  name: string;
  submit: string;
  success: string;
  fail: string;
}

/**
 * Initialized localized strings
 */
const strings: Localized = new LocalizedStrings({ en: en, fi: fi });
const params = new URLSearchParams(window.location.search);
const language = params.get("language");
strings.setLanguage(language || "fi");

export default strings;