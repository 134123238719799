import React, { useState } from "react";
import strings from "localization/strings";
import { useSearchParams } from "react-router-dom";
import { Alert, Box, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const emailExpression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

/**
 * Application component
 */
const App: React.FC = () => {
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [feedback, setFeedback] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationSeverity, setNotificationSeverity] = useState<"success" | "error">("success");
  const [notificationMessage, setNotificationMessage] = useState("");

  const sourceSystem = searchParams.get("sourceSystem") ?? process.env.REACT_APP_SOURCE_SYSTEM ?? "";
  const machineCode = searchParams.get("machineCode") || "";

  /**
   * Creates the feedback string
   *
   * @returns feedback body
   */
  const getFeedbackBody = () => {
    const feedbackBody: {
      sourceSystem: string;
      feedback: string;
      machineCode: string;
      email?: string;
      name?: string
    } = {
      sourceSystem: sourceSystem,
      feedback: feedback,
      machineCode: machineCode
    };

    if (emailAddress) {
      feedbackBody.email = emailAddress;
    }

    if (name) {
      feedbackBody.name = name;
    }

    return JSON.stringify(feedbackBody);
  };

  /**
   * Submits the feedback to lambda
   */
  const onSubmit = async () => {
    if (!process.env.REACT_APP_GATEWAY_ENDPOINT) {
      return;
    }

    try {
      setFeedbackLoading(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: getFeedbackBody()
      };
      await fetch(process.env.REACT_APP_GATEWAY_ENDPOINT, requestOptions);

      setEmailAddress("");
      setName("");
      setFeedback("");
      setNotificationSeverity("success");
      setNotificationMessage(strings.success);
      setNotificationOpen(true);
    } catch (error) {
      setNotificationSeverity("error");
      setNotificationMessage(strings.fail);
      setNotificationOpen(true);
    } finally {
      setFeedbackLoading(false);
    }
  };

  /**
   * Renders the email input
   *
   * If the source system is etrapanel, the email input is not rendered
   *
   * @returns email input or null
   */
  const renderEmailInput = () => {
    if (sourceSystem?.toLowerCase() === "etrapanel") return null;

    return (
      <>
        <Typography variant="subtitle1">{strings.contactEmail}</Typography>
        <TextField
          placeholder={strings.email}
          type="email"
          value={emailAddress}
          onChange={value => setEmailAddress(value.currentTarget.value)}
          error={!!emailAddress && !emailExpression.test(emailAddress)}
          sx={{ mt: 8 }}
        />
      </>);
  };

  return (
    <Box
      sx={{
        maxWidth: 800,
        mx: "auto"
      }}
    >
      <form>
        <Stack spacing={ 1 }>
          <Typography variant="h4">{ strings.title }</Typography>
          {renderEmailInput()}
          <Typography variant="subtitle1">{ strings.name }</Typography>
          <TextField
            placeholder={strings.name}
            value={name}
            onChange={value => setName(value.currentTarget.value)}
            sx={{ mt: 8 }}
          />
          <Typography variant="subtitle1">{ strings.feedback }</Typography>
          <TextField
            multiline
            placeholder={ strings.feedback }
            value={ feedback }
            onChange={value => setFeedback(value.currentTarget.value)}
            minRows={ 6 }
            sx={{ mt: 8 }}
          />
          <Stack direction="row-reverse">
            <LoadingButton
              onClick={ onSubmit }
              loading={ feedbackLoading }
              variant="contained"
              sx={{ ml: "auto" }}
            >
              { strings.submit }
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={ notificationOpen }
        autoHideDuration={ 4000 }
        onClose={ () => setNotificationOpen(false) }
      >
        <Alert
          severity={ notificationSeverity }
          variant="filled"
          onClose={ () => setNotificationOpen(false) }
        >
          { notificationMessage }
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default App;